
.texto-branco {
  color: #fff;
  padding-top: 5px;
}

.swipe-close {
  position: absolute;
  width: 40px;
  background-color: #fff;
  height: 3px;
  border-radius: 5px;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
}

ion-modal {
  --ion-background-color: var(--jaz-color-background-padrao);
}

.modal-detaelhes, .detalhes-conteudo {
  background-color: var(--jaz-color-background-padrao);
}

.background-padrao {
  background-color: var(--jaz-color-background-padrao);
}

.detalhes-conteudo .titulo {
  color: var(--jaz-color-texto-padrao);
  font-size: 17px;
  font-weight: bold;
  margin: 15px 0 20px;
}

.detalhes-conteudo .poligono-imagem {
  min-width: 300px;
  min-height: 300px;
}

.detalhes-conteudo .dados
, .detalhes-conteudo .memorial{
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}

.detalhes-conteudo .memorial {
  justify-content: center;
}

.detalhes-conteudo .memorial .memorial-container {
  width: 100%;
}

.detalhes-conteudo .dados .item {
  width: 50%;
  padding: 0 5px;
}

.detalhes-conteudo .memorial .item {
  justify-content: center;
}

.detalhes-conteudo .dados .item .label
, .detalhes-conteudo .memorial .item .label{
  color: silver;
  font-size: 14px;
  display: block;
}

.detalhes-conteudo .dados .item .dado
, .detalhes-conteudo .memorial .item .dado{
  color: var(--jaz-color-texto-padrao);
  font-weight: bold;
  font-size: 14px;
}

.detalhes-conteudo .memorial .item .dado {
  font-size: 13px;
}

.detalhes-conteudo .dados .poligono-svg {
  display: inline;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.detalhes-conteudo .dados .substancia {
  display: flex;
  align-items: center;
}

.detalhes-conteudo .memorial ion-segment {
  --border-radius: 0;
  border-radius: 0;
  margin: 10px 0;
}

.detalhes-conteudo .memorial .segmentacao-botao {
  --border-radius: 0;
}

.skeleton-titulo {
  height: 15px;
  width: 50%;
  margin: 0 auto;
}

/**
  iPad and Tabled
**/

@media (min-width: 768px) and (max-width: 1024px) {
  .detalhes-conteudo .dados .item {
    width: 33%;
  }
}