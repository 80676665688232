/* body, html {
  border: solid 1px red;
  max-width: 500px;
  margin: 0 auto;
} */

.desktop {
  display: flex;
  height: 100%;
  margin: 0 auto;
  width: 500px;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.conteudo {
  border: solid 1px var(--ion-color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 20px;
}

.conteudo h2 {
  text-align: center;
  display: block;
}

.no-border-radius {
  --border-radius: 0 !important;
}

.label-default {
  font-size: 16px !important;
  font-weight: bold;
}

.btn-link, .btn-link button {
  --border-color: transparent !important;
  border: 0 !important;
  top: -5px;
  font-size: 15px;
  position: relative;
  background: transparent !important;
}

.label-default .btn-link {
  font-weight: bold !important;
}

.popover {

}

.popover .popover-content {
  border-radius: 0 !important;
}

.popover .popover-content {
  padding: 10px;
}

.vertical-full {
  display: flex;
  align-items: center;
}

.vertical-full .btn-vertical-full {
  align-self: stretch;
  height: auto;
  margin: 0;
  width: 100%;
  font-size: 14px;
}

.logo-jazida__titulo {
  position: absolute;
  z-index: 10;
  width: 95px;
  top: 20px;
  left: 20px;
}

.toast-mensagem {
  --border-radius: 0;
}

.header-default {
  position: sticky;
  top: 0;
  height: 20vh;
}

.header-default .titulo-header {
  z-index: 10;
  position: absolute;
}

.icon-monitorar-processos {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto 30px;
  background-size: cover;
  background-color: var(--ion-color-primary);
}

.icon-color-white {
  color: var(--jaz-color-background-branco);
  font-weight: bold;
}

.jazida-alerta button.alert-button.btn-cancelar {
  color: var(--jaz-color-background-preto);
}

.jazida-alerta button.alert-button.btn-remover {
  color: var(--ion-color-danger);
}

.esconder-elemento {
  display: none !important;
}